<template>
  <header>
    <b-navbar toggleable="lg" type="dark">
      <b-navbar-brand href="/">
        <img
          class="navbar-brand-image"
          alt="Zauberhaft - Braut und Festmoden"
          src="../../assets/zauberhaft-braut-logo.png"
        />
        <h2 class="navbar-brand-title mb-0">Zauberhaft</h2>
        <span class="navbar-brand-subtitle">Braut&dash; und Festmoden</span>
      </b-navbar-brand>

      <b-navbar-toggle target="nav-collapse">
        <img src="../../assets/zauberhaft-schmetterling.png" />
      </b-navbar-toggle>

      <b-collapse id="nav-collapse" class="navbar-collapse">
        <b-navbar toggleable="lg" type="dark">
          <!-- Menu -->
          <b-navbar-nav class="navbar-primary">
            <b-nav-item
              v-for="route in routes"
              :key="route.path"
              :to="route.path"
              v-bind:class="{
                active: currentRoute.path === route.path,
                'd-none': route.name === 'Fehler',
                external: route.class === 'external',
              }"
            >
              <template v-if="route.icon">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="26"
                  height="26"
                  fill="currentColor"
                  class="bi bi-bag-heart"
                  viewBox="0 0 16 20"
                >
                  <path
                    fill-rule="evenodd"
                    d="M10.5 3.5a2.5 2.5 0 0 0-5 0V4h5zm1 0V4H15v10a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2V4h3.5v-.5a3.5 3.5 0 1 1 7 0M14 14V5H2v9a1 1 0 0 0 1 1h10a1 1 0 0 0 1-1M8 7.993c1.664-1.711 5.825 1.283 0 5.132-5.825-3.85-1.664-6.843 0-5.132"
                  />
                </svg>
              </template>

              {{ route.name }}
            </b-nav-item>
          </b-navbar-nav>

          <!-- Contact -->
          <navbar-contact></navbar-contact>

          <!-- Copyright -->
          <b-navbar-nav class="navbar-secondary">
            <b-nav-text
              >&#169;{{ new Date().getFullYear() }} Mode Zauberhaft</b-nav-text
            >
          </b-navbar-nav>
        </b-navbar>
      </b-collapse>
    </b-navbar>
  </header>
</template>

<script>
import NavbarContact from "../content/NavbarContact.vue";
export default {
  components: { NavbarContact },
  name: "Header",
  data() {
    return {
      routes: this.$router.options.routes,
      expanded: false,
    };
  },
  computed: {
    currentRoute() {
      return this.$route;
    },
    currentExpanded() {
      return this.expanded;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
